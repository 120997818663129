import React from 'react';
import classNames from 'classnames/bind';
import Swiper from 'react-id-swiper';
import { Container, Row, Col, ScreenClassRender } from 'react-grid-system';
import ContainerDimensions from 'react-container-dimensions';

import styles from './Dessert.module.scss';
import MenuItemDesc from './common/MenuItemDesc';
import MenuSetLeft from './common/MenuSetLeft';
import MenuSetRight from './common/MenuSetRight';
import Divider from './common/Divider';
import menus from '../../datas/salemenu/dessert.js';
import { params2, params3, params5 } from './common/swiperParam';

const cx = classNames.bind(styles);
const { bingsus, macarons, cookies } = menus;

const Dessert = () => (
  <div>
    {/* 빙수(하절기) */}
    <MenuSetLeft
      engTitle="Bingsu"
      korTitle="빙수(하절기)"
      image={require('../../assets/images/menu/dessert/bingsu/big.png')}
      content={
        <span>
          100% 국내산 팥과 생과일을 가득 올린,
          <br />
          아티제 스테디셀러 빙수
        </span>
      }>
      <ScreenClassRender
        render={screenClass => (
          <div className={cx(['illust', 'clip1', { mobile: ['xs', 'sm', 'md'].includes(screenClass) }])} />
        )}
      />
    </MenuSetLeft>
    <div className={cx('swiper')}>
      <Container fluid>
        <Row>
          <Col xs={12} md={9} lg={7.2} offset={{ md: 1.5, lg: 2.4 }}>
            <ContainerDimensions>
              {({ width }) => {
                return (
                  <Swiper {...params3}>
                    {bingsus.map((bingsu, i) => {
                      return (
                        <div key={i} className={cx('item')}>
                          <MenuItemDesc item={bingsu} />
                        </div>
                      );
                    })}
                  </Swiper>
                );
              }}
            </ContainerDimensions>
          </Col>
        </Row>
      </Container>
    </div>

    <Divider />

    {/* 마카롱 */}
    <MenuSetRight
      engTitle="Macaron"
      korTitle="마카롱"
      image={require('../../assets/images/menu/dessert/macaron/big.png')}
      content={
        <span>
          받는 분의 마음을 사로잡을 센스있는 선물,
          <br />
          10가지 다양한 맛의 마카롱
        </span>
      }
    />
    <div className={cx('swiper')}>
      <Container fluid>
        <Row>
          <Col xs={12} sm={8} md={6} lg={4.8} offset={{ sm: 2, md: 3, lg: 3.6 }}>
            <ContainerDimensions>
              {({ width }) => {
                return (
                  <Swiper {...params2}>
                    {macarons.map((macaron, i) => {
                      return (
                        <div key={i} className={cx('item')}>
                          <MenuItemDesc item={macaron} />
                        </div>
                      );
                    })}
                  </Swiper>
                );
              }}
            </ContainerDimensions>
          </Col>
        </Row>
      </Container>
    </div>

    <Divider />

    {/* 쿠키, 양과자 */}
    <MenuSetLeft
      engTitle="Cookie"
      korTitle="쿠키, 양과자"
      image={require('../../assets/images/menu/dessert/cookie/big.png')}
      content={
        <span>
          섬세한 장인의 손길 그대로,
          <br />
          기분 좋은 달콤함을 전해줄 쿠키와 양과자
        </span>
      }>
      <ScreenClassRender
        render={screenClass => (
          <div className={cx(['illust', 'clip2', { mobile: ['xs', 'sm', 'md'].includes(screenClass) }])} />
        )}
      />
    </MenuSetLeft>
    <div className={cx('swiper')}>
      <Container fluid>
        <Row>
          <Col xs={12}>
            <ContainerDimensions>
              {({ width }) => {
                return (
                  <Swiper {...params5}>
                    {cookies.map((cookie, i) => {
                      return (
                        <div key={i} className={cx('item')}>
                          <MenuItemDesc item={cookie} />
                        </div>
                      );
                    })}
                  </Swiper>
                );
              }}
            </ContainerDimensions>
          </Col>
        </Row>
      </Container>
    </div>
  </div>
);

export default Dessert;
