import React from 'react';

import Dessert from 'components/Menu/Dessert';
import Jumbotron from 'components/misc/Jumbotron';
import TabBar from 'components/misc/TabBar';

const tabBarMenus = [
  { name: '베이커리', link: '/menu/bakery/' },
  { name: '음료', link: '/menu/beverage/' },
  { name: '디저트', link: '/menu/dessert/' },
  { name: '푸드', link: '/menu/food/' },
  { name: '상품', link: '/menu/retail/' },
];

const DessertPage = () => (
  <div>
    <Jumbotron
      engTitle="Dessert"
      korTitle="디저트"
      image={require('../../assets/images/banner/menu-dessert.jpg')}
      content={
        <span>
          최고의 재료로 깊은 풍미를 자랑하는 아티제 고유의 시그니쳐 메뉴를 만나보세요.
          <br />
          다양한 디저트는 맛있는 즐거움과 함께 고르는 기쁨을 선사합니다.
          <br />
          <br />
        </span>
      }
    />
    <TabBar index={2} data={tabBarMenus} />
    <Dessert />
  </div>
);

export default DessertPage;
